// Avoid `console` errors in browsers that lack a console.
(function() {
  var method;
  var noop = function() {};
  var methods = ['assert', 'clear', 'count', 'debug', 'dir', 'dirxml', 'error', 'exception', 'group', 'groupCollapsed', 'groupEnd', 'info', 'log', 'markTimeline', 'profile', 'profileEnd', 'table', 'time', 'timeEnd', 'timeline', 'timelineEnd', 'timeStamp', 'trace', 'warn'];
  var length = methods.length;
  var console = (window.console = window.console || {});

  while (length--) {
    method = methods[length];

    // Only stub undefined methods.
    if (!console[method]) {
      console[method] = noop;
    }
  }
}());
if (typeof jQuery === 'undefined') {
  console.warn('jQuery hasn\'t loaded');
} else {
  console.log('jQuery has loaded');
}

/*jshint jquery:true */
/*global $:true */

var $ = jQuery.noConflict();

$(document).ready(function($) {
  "use strict";

  /* global google: false */
  /*jshint -W018 */

  /*-------------------------------------------------*/
  /* =  portfolio isotope
  /*-------------------------------------------------*/

  var winDow = $(window);
  // Needed variables
  var $container = $('.iso-call');
  var $filter = $('.filter');

  try {
    $container.imagesLoaded(function() {
      $container.trigger('resize');
      $container.isotope({
        filter: '*',
        layoutMode: 'masonry',
        animationOptions: {
          duration: 750,
          easing: 'linear'
        }
      });
    });
  } catch (err) {}

  winDow.bind('resize', function() {
    var selector = $filter.find('a.active').attr('data-filter');

    try {
      $container.isotope({
        filter: selector,
        animationOptions: {
          duration: 750,
          easing: 'linear',
          queue: false,
        }
      });
    } catch (err) {}
    return false;
  });

  // Isotope Filter
  $filter.find('a').click(function() {
    var selector = $(this).attr('data-filter');

    try {
      $container.isotope({
        filter: selector,
        animationOptions: {
          duration: 750,
          easing: 'linear',
          queue: false,
        }
      });
    } catch (err) {

    }
    return false;
  });


  var filterItemA = $('.filter li a');

  filterItemA.on('click', function() {
    var $this = $(this);
    if (!$this.hasClass('active')) {
      filterItemA.removeClass('active');
      $this.addClass('active');
    }
  });

  /*-------------------------------------------------*/
  /* =  browser detect
  /*-------------------------------------------------*/
  try {
    $.browserSelector();
    // Adds window smooth scroll on chrome.
    if ($("html").hasClass("chrome")) {
      $.smoothScroll();
    }
  } catch (err) {

  }

  /*-------------------------------------------------*/
  /* =  Search animation
  /*-------------------------------------------------*/

  var searchToggle = $('.open-search'),
    inputAnime = $(".form-search"),
    body = $('body');

  searchToggle.on('click', function(event) {
    event.preventDefault();

    if (!inputAnime.hasClass('active')) {
      inputAnime.addClass('active');
    } else {
      inputAnime.removeClass('active');
    }
  });

  body.on('click', function() {
    inputAnime.removeClass('active');
  });

  var elemBinds = $('.open-search, .form-search');
  elemBinds.bind('click', function(e) {
    e.stopPropagation();
  });

  /* ---------------------------------------------------------------------- */
  /*  Accordion
  /* ---------------------------------------------------------------------- */
  var clickElem = $('a.accord-link');

  clickElem.on('click', function(e) {
    e.preventDefault();

    var $this = $(this),
      parentCheck = $this.parents('.accord-elem'),
      accordItems = $('.accord-elem'),
      accordContent = $('.accord-content');

    if (!parentCheck.hasClass('active')) {

      accordContent.slideUp(400, function() {
        accordItems.removeClass('active');
      });
      parentCheck.find('.accord-content').slideDown(400, function() {
        parentCheck.addClass('active');
      });

    } else {

      accordContent.slideUp(400, function() {
        accordItems.removeClass('active');
      });

    }
  });

  /*-------------------------------------------------*/
  /* =  Animated content
  /*-------------------------------------------------*/

  try {
    /* ================ ANIMATED CONTENT ================ */
    if ($(".animated")[0]) {
      $('.animated').css('opacity', '0');
    }

    $('.triggerAnimation').waypoint(function() {
      var animation = $(this).attr('data-animate');
      $(this).css('opacity', '');
      $(this).addClass("animated " + animation);

    }, {
      offset: '75%',
      triggerOnce: true
    });
  } catch (err) {

  }

  /*-------------------------------------------------*/
  /* =  remove animation in mobile device
  /*-------------------------------------------------*/
  if (winDow.width() < 992) {
    $('div.triggerAnimation').removeClass('animated');
    $('div.triggerAnimation').removeClass('triggerAnimation');
  }

  try {

    var SliderPost = $('.flexslider');

    SliderPost.flexslider({
      slideshowSpeed: 3000,
      easing: "swing"
    });
  } catch (err) {

  }

  /*-------------------------------------------------*/
  /* = slider Testimonial
  /*-------------------------------------------------*/

  var slidertestimonial = $('.bxslider');
  try {
    slidertestimonial.bxSlider({
      mode: 'vertical'
    });
  } catch (err) {}


  /* ---------------------------------------------------------------------- */
  /*  Header animate after scroll
  /* ---------------------------------------------------------------------- */

  (function() {

    var docElem = document.documentElement,
      didScroll = false,
      changeHeaderOn = 50;
    document.querySelector('header');

    function init() {
      window.addEventListener('scroll', function() {
        if (!didScroll) {
          didScroll = true;
          setTimeout(scrollPage, 100);
        }
      }, false);
    }

    function scrollPage() {
      var sy = scrollY();
      if (sy >= changeHeaderOn) {
        $('header').addClass('active');
      } else {
        $('header').removeClass('active');
      }
      didScroll = false;
    }

    function scrollY() {
      return window.pageYOffset || docElem.scrollTop;
    }

    init();

  })();


  /* ---------------------------------------------------------------------- */
  /*  Contact Map 2
  /* ---------------------------------------------------------------------- */
  var contact = {
    "lat": "-33.880641",
    "lon": "151.204298"
  }; //Change a map coordinate here!

  try {
    var mapContainer = $('#map2');
    mapContainer.gmap3({
      action: 'addMarker',
      marker: {
        options: {
          icon: new google.maps.MarkerImage('images/marker.png')
        }
      },
      latLng: [contact.lat, contact.lon],
      map: {
        center: [contact.lat, contact.lon],
        zoom: 16
      },
    }, {
      action: 'setOptions',
      args: [{
        scrollwheel: false
      }]
    });
  } catch (err) {

  }

});

/* ---------------------------------------------------------------------- */
/*  map street view mode function
/* ---------------------------------------------------------------------- */
function initialize() {
  var bryantPark = new google.maps.LatLng(35.639543, -77.371199); //Change a map street view cordinate here! {"lat":"-33.880641", "lon":"151.204298"}
  var panoramaOptions = {
    position: bryantPark,
    pov: {
      heading: 165,
      pitch: 0
    },
    zoom: 1
  };
  var myPano = new google.maps.StreetViewPanorama(
    document.getElementById('map'),
    panoramaOptions);
  myPano.setVisible(true);
}

try {
  google.maps.event.addDomListener(window, 'load', initialize);
} catch (err) {

}




$(document).ready(function() {
  $(".slider_land").owlCarousel({

      items : 4,


  });


  // var link = window.location.pathname;
  // $('.mainmenu li a[href="' + link + '"]').parent().addClass('active');

  //   var link = window.location.pathname;
  //   $('.side-navigation-list li a[href="' + link + '"]').parent().addClass('active');
  jQuery(function($) {

    $(".wpcf7-tel").mask("+7(999) 999-99-99");
  });


  $(".wpcf7-submit").click(function() {
    setTimeout(function() {
      $('.wpcf7-response-output').fadeOut(300);
      //alert('sfdsdf');
    }, 3000);
  });

  $(window).scroll(function() {
    if ($(this).scrollTop() > 0) {
      $('#scroller').fadeIn();
    } else {
      $('#scroller').fadeOut();
    }
  });
  $('#scroller').click(function() {
    $('body,html').animate({
      scrollTop: 0
    }, 400);
    return false;
  });


  //Revolution slider


  jQuery('.tp-banner').show().revolution({
    dottedOverlay: "",
    delay: 5000,
    startwidth: 1140,
    startheight: 600,
    hideThumbs: 200,

    thumbWidth: 100,
    thumbHeight: 50,
    thumbAmount: 5,

    navigationType: "bullett",
    navigationArrows: "",

    touchenabled: "on",
    onHoverStop: "on",

    swipe_velocity: 0.7,
    swipe_min_touches: 1,
    swipe_max_touches: 1,
    drag_block_vertical: false,

    parallax: "mouse",
    parallaxBgFreeze: "on",
    parallaxLevels: [7, 4, 3, 2, 5, 4, 3, 2, 1, 0],

    keyboardNavigation: "on",

    navigationHAlign: "right",
    navigationVAlign: "bottom",
    navigationHOffset: 0,
    navigationVOffset: 40,

    shadow: 0,

    spinner: "spinner4",

    stopLoop: "off",
    stopAfterLoops: -1,
    stopAtSlide: -1,

    shuffle: "on",

    autoHeight: "on",
    forceFullWidth: "off",



    hideThumbsOnMobile: "off",
    hideNavDelayOnMobile: 1500,
    hideBulletsOnMobile: "off",
    hideArrowsOnMobile: "off",
    hideThumbsUnderResolution: 0,

    hideSliderAtLimit: 0,
    hideCaptionAtLimit: 0,
    hideAllCaptionAtLilmit: 0,
    startWithSlide: 0,
    fullScreenOffsetContainer: ".header"
  });






  (function($) {

    //Function to animate slider captions
    function doAnimations(elems) {
      //Cache the animationend event in a variable
      var animEndEv = 'webkitAnimationEnd animationend';

      elems.each(function() {
        var $this = $(this),
          $animationType = $this.data('animation');
        $this.addClass($animationType).one(animEndEv, function() {
          $this.removeClass($animationType);
        });
      });
    }

    //Variables on page load
    var $myCarousel = $('#carousel-example-generic'),
      $firstAnimatingElems = $myCarousel.find('.item:first').find("[data-animation ^= 'animated']");


    //Animate captions in first slide on page load
    doAnimations($firstAnimatingElems);



    //Other slides to be animated on carousel slide event
    $myCarousel.on('slide.bs.carousel', function(e) {
      var $animatingElems = $(e.relatedTarget).find("[data-animation ^= 'animated']");
      doAnimations($animatingElems);
    });

  })(jQuery);


  wow = new WOW({
    animateClass: 'animated',
    offset: 100,
    callback: function(box) {
      console.log("WOW: animating <" + box.tagName.toLowerCase() + ">")
    }
  });

  wow.init();
  // document.getElementById('moar').onclick = function() {
  //   var section = document.createElement('section');
  //   section.className = 'section--purple wow fadeInDown';
  //   this.parentNode.insertBefore(section, this);
  // };




  /*placeholder*/
  $('input[data-placeholder]').each(function() {
    placeholder = $(this).attr('data-placeholder');
    $(this).val(placeholder).addClass('placeholder');
  })

  $('input[data-placeholder]').focus(function() {
    if ($(this).hasClass('placeholder')) {
      $(this).val('');
      $(this).removeClass('placeholder');
    }
  })

  $('input[data-placeholder]').blur(function() {
    placeholder = $(this).attr('data-placeholder');
    if (($(this).val() == '') || ($(this).val() == placeholder)) {
      $(this).val(placeholder);
      $(this).addClass('placeholder');
    }
  })

  // $('.zoom').fancybox({
  //   padding: [0, 0, 0, 0]
  // })


  $(function() {
    $('.carousel').carousel({
      interval: 4000
    });
  });
});
